<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    width="1280.000000pt"
    height="1126.000000pt"
    viewBox="0 0 1280.000000 1126.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,1126.000000) scale(0.100000,-0.100000)"
      fill="#ffffff"
      stroke="none"
    >
      <path
        d="M6214 11235 c-203 -51 -368 -178 -506 -386 -71 -107 -845 -1468 -2488 -4374 -715 -1264 -1174 -2077 -2245 -3970 -489 -863 -901 -1601 -915 -1640 -15 -38 -35 -111 -45 -162 -18 -91 -18 -93 3 -189 31 -137 72 -214 162 -305 88 -89 164 -134 302 -178 l97 -31 5823 0 5824 0 94 21 c251 54 400 195 460 434 25 100 25 124 0 231 -41 175 72 -29 -1500 2714 -731 1276 -1681 2934 -3024 5280 -648 1130 -1207 2100 -1244 2155 -114 171 -271 309 -412 365 -70 28 -212 60 -258 59 -20 0 -78 -11 -128 -24z m590 -1635 c238 -415 850 -1484 1361 -2375 1551 -2708 3290 -5744 3529 -6162 70 -123 125 -227 121 -233 -4 -7 -1768 -10 -5431 -10 -4926 0 -5424 1 -5424 16 0 8 116 221 259 472 273 483 985 1741 2331 4122 1224 2165 1302 2302 1777 3140 900 1586 1018 1791 1031 1788 7 -2 208 -343 446 -758z"
        fill="#ff0000"
      />
      <path
        d="M6230 7851 c-172 -37 -334 -125 -469 -255 -91 -88 -150 -170 -201 -279 -80 -169 -86 -271 -40 -618 16 -123 70 -534 120 -914 50 -379 113 -859 140 -1065 27 -206 72 -548 100 -760 54 -416 73 -502 135 -605 52 -87 110 -141 194 -183 65 -32 84 -37 166 -40 202 -9 346 81 436 273 46 100 60 164 103 500 50 380 200 1520 307 2333 58 438 90 720 90 777 0 214 -97 414 -284 591 -225 213 -518 303 -797 245z"
        fill="#ff0000"
      />
      <path
        d="M6320 2731 c-239 -33 -439 -205 -516 -446 -33 -101 -37 -263 -10 -364 144 -534 820 -654 1122 -200 156 234 146 543 -25 769 -131 174 -358 269 -571 241z"
        fill="#ff0000"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Attention',
}
</script>
